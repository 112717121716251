import { Link } from "gatsby";
import React from "react";
import { FaCartPlus, FaFacebook, FaInstagram } from "react-icons/fa";

const Navbar = () => {
  return (
    <div
      className="flex font-mono justify-between items-center
      mx-2 md:mx-10
    mb-4 p-4 
    border-gray-300  text-black  
    rounded-b-sm z-20"
    >
      <div
        className="cursor-pointer font-bold text-lg md:text-3xl md:ml-4
      "
      >
        <Link to="/">
          Andrea <br></br> Guerra
        </Link>
      </div>
      <ul className="text-lg gap-x-2 lg:gap-x-10 hidden md:flex">
        <li className="links">
          <Link to="/people">People</Link>
        </li>
        <li className="links ">
          <Link to="/products">Products</Link>
        </li>
        <li className="links ">
          <Link to="/events">Events</Link>
        </li>

        <li className="links ">
          <Link to="/contactme">Contact</Link>
        </li>
        <li className="links mt-1">
          <a
            href="https://instagram.com/andreaaa.guerra?igshid=YmMyMTA2M2Y="
            target="_blank"
          >
            <FaInstagram />
          </a>
        </li>
      </ul>
      <ul className="block text-4xl md:hidden cursor-pointer">=</ul>
    </div>
  );
};

export default Navbar;
