import React from "react";
import Navbar from "../components/Navbar";

const Services = () => {
  return (
    <main>
      <Navbar />
      <div>
        <div className="flex font-mono gap-4 flex-col items-center justify-center">
          <div>
            <h1>Bodas</h1>
          </div>
          <div>
            <h1>Viajes</h1>
          </div>
          <div>
            <h1>Tomas de foto</h1>
          </div>
        </div>
        <div className="flex justify-center"></div>
      </div>
    </main>
  );
};

export default Services;
